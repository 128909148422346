<template>
    <div class="min-vh-100 d-flex flex-column">
<!--        <div class="container-fluid p-5 bg-white">-->
<!--            <a href="/">-->
<!--            <img src="/dealbuzzer_logo_black.svg" alt="DEAL BUZZER Logo" class="mx-auto d-block" style="width:100px">-->
<!--            </a>-->
<!--            <a href="/" class="btn btn-sm btn-secondary d-md-none position-absolute btn-back">Zurück</a>-->
<!--        </div>-->
        <div class="w-100 d-flex justify-content-center">
            <Confetti/>
            <div class="w-100 min-vh-100 pb-5 pb-md-0">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useWebsockets} from '@/store/websockets'
import {useBoardsFilterStore} from "@/store/boardsFilter";
import {useToast} from "vue-toastification";

const boardsFilterStore = useBoardsFilterStore()

onMounted(async () => {
    boardsFilterStore.getFilter()
    await useWebsockets().init('board', useRoute().params.token)
})

</script>

<style scoped>
    .btn-back {
        top: 1rem;
        right: 1rem;
    }
</style>